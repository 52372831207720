const reviewsSlider = () => {

  $(document).ready(function(){
    $('.reviews__slider-desktop').slick({
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      centerMode: true,
      centerPadding: '0',
      prevArrow: $('.prev'),
      nextArrow: $('.next'),
      vertical: true,
      verticalSwiping: true,
      arrows: true

    });
  }); 

};

export default reviewsSlider;

