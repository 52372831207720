const headerBg = () => {
    const header = document.querySelector('.header'),
          headerHeight = header.style.height;
    

    document.addEventListener('scroll', () => {
        const windowScroll = window.scrollY || document.documentElement.scrollTop;
        windowScroll > headerHeight ? header.style.backgroundColor = 'rgba(0, 2, 47, 90%)' : header.style.backgroundColor = 'initial';
    });

};

export default headerBg;

